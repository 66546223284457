import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getPostClassName } from '../../utils';
import { BlogPost } from '../../constants';

interface IProps {
  posts: BlogPost[];
}

const BlogPostsRenderer: React.FC<IProps> = ({ posts }) => (
  <Row className="blog-post-row">
    {posts.map((post, index) => {
      const { frontmatter, fields, excerpt } = post;
      const { title, coverImage } = frontmatter;
      const { slug } = fields;

      return (
        <Col
          key={title}
          className={`
                px-0 py-0
                ${getPostClassName(posts.length, index)}
              `}
          md={6}
          lg={4}
        >
          <a className="anchor-no-decoration blog-post-card" href={slug}>
            <article>
              <GatsbyImage
                image={coverImage.childImageSharp.gatsbyImageData}
                alt=""
              />
              <div className="blog-caption-background latest-caption-container">
                <h5>{title}</h5>
                <div>{excerpt}</div>
                <div className="d-flex flex-row justify-content-between mt-auto">
                  <div className="upper ">View Post &gt;</div>
                </div>
              </div>
            </article>
          </a>
        </Col>
      );
    })}
  </Row>
);

export default BlogPostsRenderer;
