import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import DownArrow from '../images/arrows/down-black.svg';
import BlogPostsRenderer from './PostRenderers/BlogPostRenderer';
import InstaPostsRenderer from './PostRenderers/InstaPostRenderer';
import { InstaPost, BlogPost } from '../constants';

const imagesPerSet = 9;
interface IProps {
  allPosts: InstaPost[] | BlogPost[];
  PostsRenderer: typeof BlogPostsRenderer | typeof InstaPostsRenderer;
}

const SocialPostsContainer: React.FC<IProps> = ({
  allPosts,
  PostsRenderer,
}) => {
  const [postsToShow, setPostsToShow] = useState(imagesPerSet);

  const increaseImagesShown = () => {
    setPostsToShow(postsToShow + imagesPerSet);
  };

  const latestNodes = allPosts.slice(0, postsToShow);

  return (
    <div className="posts-container">
      <PostsRenderer posts={latestNodes} />
      {(true || postsToShow < allPosts.length) && (
        <Button
          type="button"
          className="show-more-btn"
          onClick={increaseImagesShown}
        >
          <img alt="Show more posts" src={DownArrow} />
        </Button>
      )}
    </div>
  );
};

export default SocialPostsContainer;
