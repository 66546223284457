import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageWrapper from '../components/PageWrapper';
import SocialPostsContainer from '../components/SocialPostsContainer';
import { BlogPost } from '../constants';
import BlogPostRenderer from '../components/PostRenderers/BlogPostRenderer';

const pageTitle = 'Blog';
interface IProps {
  data: {
    blog: {
      posts: BlogPost[];
    };
  };
}

const Blog: React.FC<IProps> = ({ data }) => {
  const { posts } = data.blog;

  return (
    <PageWrapper>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content="Find out about us." />
      </Helmet>
      <Container>
        <Row>
          <Col>
            <h1 className="text-center upper">Blog</h1>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <SocialPostsContainer
          allPosts={posts}
          PostsRenderer={BlogPostRenderer}
        />
      </Container>
    </PageWrapper>
  );
};

export const pageQuery = graphql`
  query MyQuery {
    blog: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          coverImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
            }
          }
        }
        fields {
          slug
        }
        excerpt(pruneLength: 105)
        id
      }
    }
  }
`;

export default Blog;
